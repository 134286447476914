$(document).foundation();

function footerYear(){
    var currentTime = new Date();
    var year = currentTime.getFullYear();
    //var curYear=(new Date()).getFullYear();
    $("#copyrights #yearNow").html("2017 - ".year);
}
/*Sticky Footer*/

function stickyFooter(){
    var footer = $("footer");
    footer.removeClass('sticked');
    var pos = footer.position();
    var height = $(window).height();
    var headerH = $("header").outerHeight();
    height = height - pos.top - headerH;
    height = height - footer.outerHeight();
    if (height > 0) {
        if (footer.hasClass('sticked')) {}
        else{footer.addClass('sticked');}
    }
}

function scrollMenu(){
    var header = $('header');
    var topNow = $(window).scrollTop();

    if (topNow >= 1) {
        header.addClass('slim');
    }
    else {
        header.removeClass('slim');
    }

}

function scrollCallBack() {
    if($('.callfly').offset().top + $('.callfly').height() >= $('footer').offset().top - 10){
        $('.callfly').addClass('stay');
    }
    if($(document).scrollTop() + window.innerHeight < $('footer').offset().top){
        $('.callfly').removeClass('stay'); // restore when you scroll up
    }
}

function flyMessagers() {
    if($('.flymessagers').offset().top + $('.flymessagers').height() >= $('footer').offset().top - 10){
        $('.flymessagers').addClass('stay');
    }
    if($(document).scrollTop() + window.innerHeight < $('footer').offset().top){
        $('.flymessagers').removeClass('stay'); // restore when you scroll up
    }
}


function adaptiveMenu(){
    $(".top-menu").css({'display': 'block'});
    var widthW = $(window).width();
    if ( widthW <= 1200 ){
        if($(".top-menu, .menuIcon").hasClass('mobile')){
            $(".top-menu").css('display', 'none');
        }
        else{
            $(".top-menu, .menuIcon").addClass('mobile');
            $(".top-menu").css({display : 'none', opacity: 0, top: -500});
        }
    }
    else{
        $(".top-menu, .menuIcon").removeClass('mobile');
        $(".top-menu").css({opacity: '1',top: '0'});
    }
}

function CallbackPopUpClose(){
    $("#callback").animate({top: -300},
        500, function() {
            $("#callbackWrapper").css('display', 'none');
            $('#CallBackForm').trigger("reset");
            $("#CallBackForm div").removeClass("error");
            $("#wrapper").removeClass('blur');
        });
}


function socialPopUpClose(){
    $("#social-icons").animate({top: -300},
        500, function() {
            $("#socialWrapper").css('display', 'none');
            $("#wrapper").removeClass('blur');
        });
}
let shelfPlaceholder;
function Shelf(){
    $('.shelf .show-more .text').click(function(){
        if ($(this).parents('.shelf').hasClass('open')){
            $(this).text(shelfPlaceholder);
        }
        else {
            shelfPlaceholder = $(this).text();
            $(this).text($(this).attr('data-open'));
        }
        $(this).parents('.shelf').toggleClass('open');
    });
}

$(document).ready(function() {
    // ON LOAD FUNCS
    adaptiveMenu();
    scrollMenu();
    flyMessagers();
    footerYear();
    Shelf();

    $('.language-dropdown .selected-language').click(function() {
        $('.language-dropdown').toggleClass('open');
    });

    $('.language-dropdown .language-list li').click(function() {
        var langFlag = $(this).find('img').attr('src');
        $(this).siblings('li').toggleClass('active');
        $(this).toggleClass('active');
        $('.language-dropdown .selected-language img').attr('src', langFlag);
        $('.language-dropdown').removeClass('open');
    });

    $('.callfly .activate').click(function(e){
        $('.callflywrap').toggleClass('open');
        $('.textwrap').css('transform', 'translateX(-15px)');
    });


    $('.callfly .item.close, .callflyclose').click(function(e){
        $('.textwrap').css('transform', 'translateY(500px)');
        setTimeout(function() {
            $('.callflywrap').toggleClass('open');
        }, 200);

    });

    // HANDLERS
    $('.phone-cont').hover(function() {
        $('.other-phones').css('display', 'block').animate({opacity: 1}, 300);
    }, function() {
        $('.other-phones').css('display', 'none').animate({opacity: 0}, 300);
    });

    $('.callbtn').click(function(e) {
        var oType = $(this).data('otype');
        $("#CallBackForm #cb_type").val(oType);

        $("#callbackWrapper").show();
        $(".callbackCloseSpace").animate({opacity: 1},300);
        $("#callback").animate({top: 0}, 400);
        $("#wrapper").addClass('blur');
    });

    $('.socbtn').click(function(e) {
        $("#socialWrapper").show();
        $(".socialCloseSpace").animate({opacity: 1},300);
        $("#social-icons").animate({top: 0}, 400, function(){
            $("#social-icons .social-item, #social-icons .title").addClass('show');
        });
        $("#wrapper").addClass('blur');
    });

    $(".callbackCloseSpace").click(function(e) {
        $("#callback").animate({
                top: -300},
            300, function() {
                $("#wrapper").removeClass('blur');
                $(".callbackCloseSpace").animate({
                        opacity: 0},
                    300, function() {
                        $("#callbackWrapper").hide();
                    });
            });

    });

    $(".socialCloseSpace").click(function(e) {
        $("#social-icons").animate({
                top: -400},
            300, function() {
                $("#wrapper").removeClass('blur');
                $("#social-icons .social-item,#social-icons .title").removeClass('show');
                $(".socialCloseSpace").animate({
                        opacity: 0},
                    300, function() {
                        $("#socialWrapper").hide();
                    });
            });
    });

    // Menu

    $(".menuIcon").click(function() {
        $(".top-menu").css('display', 'block');
        $(".top-menu").animate({opacity: 1, top: 0}, 500);
    });

    $(".closeMenu").click(function() {
        $(".top-menu").animate({
                opacity: 0,
                top: -500},
            300, function() {
                $(".top-menu").css('display', 'none');
            });
    });

    stickyFooter();

    // Scroll handler
    $(window).scroll(function(){
        scrollMenu();
        flyMessagers();
    });

    // Resize handler
    $(window).resize(function() {
        adaptiveMenu();
        stickyFooter();
        flyMessagers();
    });

    // Ajax ready
    $( document ).ajaxComplete(function() {
        stickyFooter();
        flyMessagers();
    });

    // Change orientation handler
    window.addEventListener("orientationchange", function() {
        stickyFooter();
        adaptiveMenu();
        flyMessagers();
    }, false);

});
